$color1: rgba(43, 37 , 42, 1);
$color2: rgba(255, 71 , 227, 1);
$color3: rgba(56, 178 , 255, 1);
$color4: rgba(237, 241 , 255, 1);
$color5: rgba(106, 13 , 173, 1);

$color-main-bg: rgba(214, 242, 236, 1);
$color-sec-theme: rgba(69, 89, 102, 1);
$color-main-theme: rgba(110, 226, 216, 1);
$color-main-fg: rgba(48, 54, 53, 1);
$color-sec-bg: rgba(87, 131, 125, 1);
$color-sec-fg: rgba(21, 21, 36, 1);

$color-primary: #5BC3BC; // rgb(198, 29, 93)
$color-secondary: #C4F0EB;
$color-main: #FAFFFE;
$color-button-hocus: #3F888A;