// #region Document Start
html, body
{
    background: $color-main;
    color: $color-primary;
}
// #endregion Document End

// #region Buttons
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
    background-color: #dadada !important;
     border-color: #9b9b9b !important;
     color: #555555;
}

.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
    color: #5bc3bc !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
    color: $color-main;
    background-color: #5bc3bc !important;
    border-color: #5bc3bc !important;
    transform: none;
}

.btn-outline-secondary:hover {
    color: $color-main;
    background-color: #5bc3bc !important;
    border-color: #5bc3bc !important;
    transform: none;
}

.btn-cancel-text {
    color: rgba(0, 0, 0, 0.5);
}

.calnder-button {
    color: $color-main;
    background-color: $color-primary !important;
    border: 1px solid $color-primary !important;
}

.backbtn {
    color: rgb(85, 85, 85) !important;
    background-color: rgb(189, 189, 189) !important;
}

.backbtn:hover {
    background-color: rgb(189, 189, 189) !important;
}

.btn-edit {
    background-color: rgb(189, 189, 189) !important;
}
// #endregion Buttons

// #region Label
label {
    color: $color-primary;
}


// #endregion

// #region Form Colors
.form-control,
.form-control:hover,
.form-control:focus {
    border-color: #c8c8c87d;
    box-shadow: rgba(196, 240, 235, 0.1) 0px 1px 3px 0px;
}
// #endregion

// #region Alert Colors
.alert-primary {
    color: $color-main;
    background-color: #5bc3bc;
    // border-color: black;
}

// #endregion

// #region Table Colors
.table thead th {
    background: #5bc3bc !important;
    color: white!important;
}

.table tr:nth-child(even) {
    background: #fcfcfc;
}


.table tr:nth-child(odd) {
    background: #ffffff;
}

body .ui-table .ui-table-thead>tr>th {
    background-color: #5bc3bc !important;
    color: $color-main !important;
}

.ui-table .ui-table-tbody>tr:nth-child(odd) {
    background: $color-main !important;
}

.ui-table .ui-table-tbody>tr:nth-child(even) {
    background: #efe9f4 !important;
}

body .ui-table .ui-table-tbody>tr:focus+tr>td {
    box-shadow: 0 -0.1em 0 0 $color-primary !important;
}

body .ui-table .ui-table-tbody>tr:focus>td {
    box-shadow: 0 -0.1em 0 0 $color-primary, 0 0.1em 0 0 $color-primary!important;
}
// #endregion

// #region Costume

.useractive {
    border-bottom: 4px solid $color-primary;
}

.modal-content {
    background-color: rgb(245, 245, 245) !important;
}

// #endregion

// #region Calendar
body .fc .fc-toolbar .fc-button {
    color: $color-main;
    background-color: $color-primary !important;
    border: 1px solid $color-primary !important;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.fc-toolbar h2 {    
    color: #555555 !important;
}

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
    color: $color-primary !important;
}

p-fullcalendar.monthly-calnd .fc-unthemed td {
    border-color: $color-main !important;
    // background-color: #c1c1c1 !important;
    background-color: #DEDEDE !important;
}

p-fullcalendar.monthly-calnd .fc th {
    background-color: $color-main !important;
    border: 1px solid $color-main !important;
    color: $color-primary !important;
}

p-fullcalendar.monthly-calnd .fc .fc-row {
    border-right: 1px solid $color-main !important;
}

p-fullcalendar.monthly-calnd .fc-unthemed td.fc-today {
    // background: #c1c1c1 !important;
    border-top: 5px Solid rgb(133, 20, 63) !important;
}
// #endregion

// #region Date picker
body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select {
    border-bottom: 2px solid $color-primary !important;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    color: $color-primary !important;
}

body .ui-datepicker table td.ui-datepicker-today>span {
    background-color: $color-primary !important;
    color: $color-main !important;
}
// #endregion

// #region Side and Nav bars
.sidebar {
    border-right: 2px solid #cccccc94;
    background: $color-main;
}

.sidebar .nav-item .nav-link {
    color: $color-primary;
}

.sidebar .nav-item .nav-link:hover {
    color: $color-primary;
}

#wrapper #content-wrapper {
    /* background-color: #f5f7fa; */
    background-color: $color-main;
}

.toggle-button,
.toggle-button:hover,
.toggle-button:focus,
.toggle-button:active {
    background: none;
    color: $color-primary;
}

.sidebar .nav-item .nav-link.active {
    background: $color-primary;
    color: $color-main;
}

.activeborder {
    border-left: 5px solid deeppink;
}

a:hover {
    color: none !important;
}

.navbar {
    background: $color-main;
}

.navbar-light {
    background: $color-main;
}
// #endregion

ul#accordionSidebar:before {
    background: #9e9e9e52;
}

.notification-bubble {
    background: #e91f1f;
}

// #region Upload

.upload-cricle {
    background: #bdbdbd40;
    box-shadow: 0 0 5px #0000005c;
}

.imgborder {
    border: 2px solid #2d2121;
}
// #endregion

// #region Toolbar
.toolbar-input-group {
    border: 1px solid #b41e8d;
    background: $color-main;
}

.toolbar-input-group .btn span {
    color: #b41e8d;
}

.toolbar-input-group .form-control {
    color: #b41e8d;
}
// #endregion

// #region Tabs
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show {
    color: $color-primary !important;
    background-color: $color-main !important;
    border-bottom: 5px solid $color-primary !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: $color-primary $color-primary $color-primary;
}

.nav-tabs .nav-link {
    color: $color-primary !important;    // 87
}

// #endregion

// #region Page Link
.page-link {
    color: $color-primary;
}

.page-link.active {
    color: $color-main;
    background: rgb(133, 20, 63);
}

.page-item:first-child .page-link {
    border: 1px solid #85143f;
}

// #endregion

// #region Check Box
.custom-checkbox :hover input~.checkmark {
    background-color: #ccc;
}

.custom-checkbox input:checked~.checkmark {
    background-color: $color-main;
}

.custom-checkbox .checkmark:after {
    border: solid #5bc3bc !important;
}
// #endregion

// #region Paginator

.ui-paginator .ui-paginator-next,
.ui-paginator .ui-paginator-last,
.ui-paginator .ui-paginator-first,
.ui-paginator .ui-paginator-prev,
.ui-paginator .ui-paginator-current {
    background: $color-primary !important;
    color: $color-main !important;
}

.ui-paginator-page .ui-paginator-element {
    color: $color-primary !important;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
    background-color: $color-primary !important;
    color: $color-main !important;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
    background-color: $color-primary !important;
    color: $color-main !important;
}

body .ui-paginator {
    background-color: $color-main !important;
}
// #endregion

// #region Anchors
.text-black {
    color: #000;
}

.text-ruby {
    color: $color-primary !important;
}

.bg-ruby {
    background: $color-primary;
}

.tour-block {
    background: #abb8dbcc;
}

.text-light-pink {
    color: $color-primary; // 87
}

.text-purple {
    color: #162e3e !important;
}

.bg-purple {
    background: #5bc3bc !important;
}

.text-Bilbao {
    color: #85143f;
}

.bg-Bilbao {
    background: #85143f;
}

.text-Maroon-Flush {
    color: $color-primary;
}

.bg-Maroon-Flush {
    background: $color-primary;
}

.text-Frostbite {
    color: #555;
}

.bg-Frostbite {
    background: #555;
}

.text-davy-grey {
    color: #555555;
}

.bg-davy-grey {
    background: #555555;
}

.text-silver {
    color: #bdbdbd;
}

.bg-silver {
    background: #bdbdbd;
}

.bg-whisper {
    background: rgb(230, 230, 230);
}

.text-nero {
    color: #242424;
}

.bg-nero {
    background: #242424;
}

.text-white-smoke {
    color: #f5f5f5;
}

.bg-white-smoke {
    background: #f5f5f5;
}

.text-Bourbon {
    color: #B8711C;
}

.bg-Bourbon {
    background: #B8711C;
}

.text-Cinnabar {
    color: #B8301C;
}

.bg-Cinnabar {
    background: #B8301C;
}

.text-Catalina-Blue {
    color: #144985;
}

.bg-Catalina-Blue {
    background: #144985;
}


.bg-red {
    background-color: #FF0000;
}

.text-red {
    color: #FF0000;
}

.bg-blue {
    background-color: #0000FF;
}

.bg-yellow {
    background-color: #FFFF00;
}

.bg-orange {
    background-color: #FFA500;
}

.bg-purple {
    background-color: #800080;
}

.bg-pink {
    background-color: #FFC0CB;
}

.bg-brown {
    background-color: #A52A2A;
}

.bg-green {
    background-color: #008000;
}

.text-pinkswan {
    color: #B6B6B6 !important;
}

.bg-pinkswan {
    background-color: #B6B6B6 !important;
}

.text-disco {
    color: #162E3E;
}

.bg-disco {
    background-color: #162E3E;
}

.bg-islamicgreen {
    background-color: #00B518;
}

.text-nightrider {
    color: #333333;
}

.txt-blackshadow {
    text-shadow: 0px 0px 0px black;
}


::ng-deep .p-datatable .p-sortable-column .p-sortable-column-icon {
	color: white!important;
	// margin-left: 0.5rem;
}
// #endregion