/*IMPORT BASE STYLES*/
@import "assets/sass/base";

@import 'ngx-toastr/toastr';
@import 'material-icons/iconfont/material-icons.css';
// @import '../node_modules/@syncfusion/ej2-base/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';

/* IMPORT CSS ENDS */


/* GLOBAL ELEMENTS STARTS */


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}


/* GLOBAL ELEMENTS STARTS */

body {
    margin: 0;
    padding: 0;
    font-feature-settings: "liga" 0;
}


/* BUTTONS STARTS  */

.btn,
.btn:hover,
.btn:focus,
.btn:active {
    font-size: 16px;
    font-weight: bold;
    outline: none;
    box-shadow: none !important;
    border-radius: 10px;
    padding: 0.8rem 1.5rem;
}

.btn-primary:focus {
    // border: none !important;
    outline: none !important;
}

/*  BUTTON ENDS  */


/* FORM CONTROLS STARTS */

// label {
//     color: #85143f;
//     font-size: 18px;
//     font-weight: 600;
// }
label {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
}

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-color: $color-main !important;
}

.note-editor.note-frame {
    border: 1px solid #c8c8c8 !important;
}


/* FORM CONTROLS ENDS */

/* TABLE STARTS */

.table thead th {
    // font-weight: 600;
    // font-size: 20px;
}

table td {
    font-size: 18px;
}

a {
    cursor: pointer !important;
}


/* TABLE ENDS */


/* GLOBAL ELEMENTS ENDS */


/* BRAND GUIDE STARTS */

.close {
    float: right;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1;
    color: #000;
    text-shadow: 0px 1px 0px $color-main;
    opacity: 1.5;
}

a:hover {
    text-decoration: none !important;
}

.small-button {
    display: flex;
    justify-content: center !important;
    height: 48px;
    width: 48px;
    box-shadow: none !important;
    border-radius: 10px !important;
}

.calnder-button {
    display: flex;
    justify-content: center !important;
    height: 38px;
    width: 38px;
    padding: inherit !important;
    transition: background-color 0.2s, box-shadow 0.2s !important;
    border-radius: 6px !important;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s !important;
    -moz-transition: background-color 0.2s, box-shadow 0.2s !important;
    -ms-transition: background-color 0.2s, box-shadow 0.2s !important;
    -o-transition: background-color 0.2s, box-shadow 0.2s !important;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none !important;
}

.btn-primary.focus,
.btn-primary:focus {
    color: $color-main;
    box-shadow: none !important;
}


/* BRAND GUIDE ENDS */


/* NEWLY ADDED BY SRIKANTH */

.container-fluid {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
}

.border-radius-8 {
    border-radius: 8px;
    /* color:rgba(133, 20, 63, 0.5) */
}

.width100P {
    width: 100%;
}

.w-625 {
    width: 625px;
}

.height100VH {
    height: 100vh;
}

.ml-n1 {
    margin-left: -0.25rem !important;
}

.ml-n2 {
    margin-left: -0.50rem !important;
}

.ml-n3 {
    margin-left: -1rem !important;
}

.ml-n4 {
    margin-left: -2rem !important;
}

.ml-n5 {
    margin-left: -3rem !important;
}

.ml-n6 {
    margin-left: -4rem !important;
}

.ml-n7 {
    margin-left: -5rem !important;
}


/* margin top */

.mt-n1 {
    margin-top: -0.25rem !important;
}

.mt-n2 {
    margin-top: -0.50rem !important;
}

.mt-n3 {
    margin-top: -1rem !important;
}

.mt-n4 {
    margin-top: -2rem !important;
}

.mt-n5 {
    margin-top: -3rem !important;
}

.font-20 {
    font-size: 20px;
    text-decoration: none !important;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.navbar {
    height: 100px;
    padding: 0;
    z-index: 3;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.headerimg {
    height: 50px;
    width: 50px;
}

.font-35 {
    font-size: 35px !important;
}

.font-30 {
    font-size: 30px !important;
}

.font-19 {
    font-size: 19px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-26 {
    font-size: 26px;
}

.font-40 {
    font-size: 40px !important;
}

.font-18 {
    font-size: 18px;
}

.line-h-1 {
    line-height: 1.5;
}

.btn {
    font-size: 18px !important;
}

.useractive {
    font-weight: 600;
    text-decoration: none;
    text-decoration-style: solid;
    display: inline-block;
    padding-bottom: 2px;
    border-radius: 3px 2px 3px 3px;
}

.custome-shadow {
    box-shadow: 0 0 5px 0px #00000029;
}

.custome-width {
    width: 180px;
}

.card {
    padding: 16px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px 0px;
    box-shadow: rgb(0 0 0 / 18%) 0px 1px 10px 0px;
    border-width: 1px;
    border-radius: 10px;
    padding: 8px;
}


/* check boxes styles */

.form-check .custom-control-label {
    font-size: 18px;
    line-height: 2;
    padding-left: 15px;
    text-transform: capitalize;
    font-weight: 600;
}

.form-check .custom-control-label::after,
.form-check .custom-control-label::before {
    height: 25px;
    width: 25px;
}

.form-check .custom-control-label::before {
    background-color: rgb(218, 218, 218);
    border: 1px solid #34353445 !important;
    border-radius: 10%;
}

/* When I remove background the icon goes back to that default size */

.custom-control-input:checked~.custom-control-label::before {
    background-color: #5bc3bc !important;
    outline: 0px;
    border: none !important;
}

.custom-control-input:checked~.custom-control-label::after:hover,
.custom-control-input:checked~.custom-control-label::before:focus {
    outline: none !important;
    border: none !important;
}

.custom-control-label::before {
    background: none;
    border: none !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: $color-main;
    background-color: $color-main !important;
    border-color: $color-main;
    border: none !important;
    outline: none !important;
}

input[type="checkbox"]:focus {
    outline: 0 !important;
}


/* end */

.sidebar::-webkit-scrollbar {
    width: 10px;
    -webkit-user-drag: initial;
}

.force-overflow::-webkit-scrollbar {
    width: 5px;
}

.sidebar {
    scrollbar-width: 10px;
}

.sidebar::-webkit-scrollbar {
    width: 10px;
    -webkit-user-drag: initial;
}

.sidebar ::-moz-scrollbar {
    width: 10px;
    -webkit-user-drag: initial;
}

::-moz-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-moz-scrollbar-thumb {
    background: #888;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
}

.force-overflow {
    overflow: scroll;
    height: 300px;
    overflow-x: hidden;
    padding-right: 15px;
}

hr {
    border: 0;
    clear: both;
    display: block;
    margin-top: 26px;
    width: 98%;
    background-color: #c0c0c085;
    height: 1px;
}

.textleft-postive {
    margin-left: -205px !important;
}

.alerticon {
    width: 45px;
}

.margin-N-42 {
    margin-left: 42px !important;
}

.userwidth {
    width: 203px;
    margin-left: -45px !important;
}

.w-320 {
    width: 320px;
}

.w-200 {
    width: 200px;
}

.shadow-none {
    box-shadow: none !important;
}

.input-group {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.w-55 {
    width: 55%;
}

.w-32 {
    width: 32%;
}

.marginleft-2p {
    margin-left: 2px;
}


/* ---------------------------- Mobile Responsive Media Quries ---------------------------------------- */

@media (min-width: 320px) and (max-width: 460px) {

    /* step-5 */
    .btn {
        font-size: 14px !important;
    }

    .res-btn-indiv {
        margin-top: 25px;
    }

    .res-item {
        margin-left: 15px;
        width: inherit;
    }

    .res-textarea {
        margin-left: 12px;
        width: 93%;
    }

    .res-margn-pl-50 {
        margin-left: 50px !important;
    }

    .res-ml-n-35 {
        margin-left: -50px !important;
    }

    .res-w-55 {
        margin-right: -30px;
        display: flex;
        align-items: center;
    }

    #res-375 {
        margin-left: 156px !important;
        margin-top: -30px;
    }
}


/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575px) {

    /* login-page */
    .sign {
        text-align: center;
        margin-top: -20px;
    }

    .loginlogo {
        margin-top: -40px !important;
    }

    /* header-part */
    .logo {
        margin-left: 20px !important;
    }

    .navbar-light .navbar-toggler {
        margin-right: 20px;
    }

    /* list-view */
    .res-right {
        float: right !important;
        margin: 0px !important;
    }

    .res-center {
        text-align: center !important;
        margin-top: 25px;
    }

    .res-margintop {
        margin-top: 30px;
        padding-left: 10px;
        margin-bottom: 20px;
    }

    .res-text {
        position: absolute;
        padding: 3px;
    }

    .res-menu {
        float: right !important;
        margin-left: -65px !important;
        clear: both !important;
    }

    /* job nformation */
    .card-data-center {
        text-align: center !important;
    }

    .res-card {
        margin-top: 20px !important;
    }

    .res-margn-n-48 {
        margin-left: -48px !important;
    }

    .res-margn-n-13 {
        margin-left: -13px !important;
    }

    .res-margn-n-32 {
        margin-left: -32px !important;
    }

    .res-margn-n-30 {
        margin-left: -30px !important;
    }

    .res-font-16 {
        font-size: 16px !important;
    }

    .res-margn-pl-30 {
        margin-left: 30px !important;
        margin-bottom: 15px !important;
    }

    .res-margn-pl-50 {
        margin-left: 70px !important;
    }

    .res-single {
        margin-left: 96px !important;
    }

    .res-float-left {
        float: left !important;
    }

    .res-w-225 {
        width: auto !important;
        margin-left: 0px !important;
    }

    .res-item {
        margin-left: 15px;
        width: inherit;
    }

    .res-textarea {
        margin-left: 12px;
        width: 93%;
    }

    .res-w-55 {
        width: 55%;
    }

    .res-ml-n-35 {
        margin-left: -35px;
    }

    /* select-day-view */
    .page-heading {
        margin-top: 20px;
        font-size: 20px;
        text-align: center !important;
    }

    .padingleft-0 {
        padding-left: 0px !important;
    }

    .padingright-0 {
        padding-right: 2px !important;
    }

    .margnleft-0 {
        margin-left: 0px !important;
    }

    .margnright-0 {
        margin-right: 0px !important;
    }

    .margintop-0 {
        margin-top: 0px !important;
    }

    .p-text {
        margin-top: 20px !important;
        margin-left: 0px !important;
    }

    .time {
        width: 100% !important;
        display: inline-flex;
    }

    .w-auto {
        width: 60% !important;
    }

    .resp-font-14 {
        font-size: 14px;
    }

    /* step-1.1 */
    .res-font {
        font-size: 18px !important;
        text-align: justify !important;
    }

    .res-margin-auto {
        text-align: center !important;
        margin: auto 0 !important;
    }

    .res-20 {
        font-size: 20px;
        line-height: inherit;
    }

    /* step-1.2 */
    .responsive-w {
        width: 100% !important;
    }

    .responsive-w1 {
        width: 92% !important;
    }

    .res-font-16 {
        font-size: 16px !important;
    }

    .font-28 {
        font-size: 28px !important;
    }

    .res-close {
        position: absolute;
        margin-top: -12px !important;
    }

    .res-btn-center {
        margin: 0 auto !important;
    }

    .res-flex {
        /* display: flex; */
        align-content: center;
        margin-left: -20px;
    }

    .res-btn-house {
        margin-right: 10px !important;
    }

    /* step-1.4 */
    .res-font-14 {
        font-size: 14px !important;
    }

    .res-font-20 {
        font-size: 20px !important;
        margin-top: -20px !important;
        text-align: center !important;
    }

    .alert-dismissible {
        padding-right: 2rem;
    }

    .res-add-btn {
        margin: 0 auto !important;
        margin-top: 20px !important;
    }

    /* step-4 */
    .res-w-250 {
        width: 250px !important;
    }

    .res-margn-top {
        margin-top: 20px !important;
    }

    .res-font-24 {
        font-size: 24px !important;
    }

    .res-marginbotm {
        text-align: center !important;
    }

    .res-step4 {
        text-align: center !important;
        margin-top: 25px;
    }

    .res-btn-no {
        margin-right: 25px !important;
    }

    /* step-5 */
    .res-padding {
        padding: 0px !important;
    }

    .res-w-100 {
        width: 100% !important;
    }

    .res-center-bottom {
        text-align: center !important;
        margin-bottom: 20px !important;
    }

    .res-font-18 {
        font-size: 18px !important;
    }

    .btn {
        font-size: 16px !important;
    }
}


/* Small devices (landscape phones, 576px and up)*/

@media (min-width: 576px) and (max-width: 767px) {

    /* login-page */
    .sign {
        text-align: center;
        margin-top: -20px;
    }

    .loginlogo {
        margin-top: -10px !important;
    }

    /* header-part */
    .logo {
        margin-left: 20px !important;
    }

    .navbar-light .navbar-toggler {
        margin-right: 20px;
    }

    .container {
        max-width: -webkit-fill-available;
        padding-right: 25px;
        padding-left: 25px;
        margin-right: auto;
        margin-left: auto;
    }

    /* list-view */
    .res-right {
        float: right !important;
        margin: 0px !important;
    }

    .res-margintop {
        margin-top: 30px;
        padding-left: 10px;
        margin-bottom: 20px;
    }

    .res-text {
        padding: 5px;
        margin-top: -6px !important;
    }

    .res-date-text {
        display: flex;
    }

    /* job nformation */
    .card-data-center {
        text-align: center !important;
    }

    .res-card {
        margin-top: 20px !important;
    }

    .res-margn-n-48 {
        margin-left: -48px !important;
    }

    .res-margn-n-13 {
        margin-left: -13px !important;
    }

    .res-margn-n-32 {
        margin-left: 10px !important;
    }

    .res-margn-n-30 {
        margin-left: 10px !important;
    }

    .res-font-16 {
        font-size: 17px !important;
    }

    .res-margn-pl-30 {
        margin-left: 30px !important;
        margin-bottom: 15px !important;
    }

    .res-mpl-25 {
        margin-left: 25px;
    }

    .res-margn-pl-145 {
        margin-left: 145px !important;
    }

    .res-float-left {
        float: left !important;
    }

    .res-text-center {
        text-align: center !important;
        margin-top: 20px !important;
    }

    .res-mpl-70 {
        margin-left: 70px !important;
    }

    .res-item {
        margin-left: 15px;
        width: inherit;
    }

    .res-textarea {
        margin-left: 12px;
        width: 93%;
    }

    .res-row-left {
        margin-left: 30px !important;
    }

    .res-margn-pl-50 {
        margin-left: 70px !important;
    }

    .res-single {
        margin-left: 95px !important;
    }

    /*-------- select-day-view ------*/
    .page-heading {
        font-size: 20px;
    }

    .padingleft-0 {
        padding-left: 0px !important;
    }

    .margnleft-0 {
        margin-left: 0px !important;
    }

    .margintop-0 {
        margin-top: 0px !important;
    }

    .p-text {
        margin-top: 20px !important;
        margin-left: 0px !important;
    }

    .time {
        width: 100% !important;
        display: inline-flex;
    }

    .w-auto {
        width: 60% !important;
    }

    /* step 1.1 */
    .res-font {
        font-size: 24px !important;
        text-align: justify !important;
    }

    .res-margin-auto {
        text-align: center !important;
    }

    /* model popup */
    .responsive-w {
        width: 100%;
    }

    .responsive-w1 {
        width: 94%;
    }

    /* step-1.4 */
    .res-font-14 {
        font-size: 20px !important;
    }

    .res-font-20 {
        font-size: 25px !important;
        margin-top: -20px !important;
        text-align: center !important;
    }

    .alert-dismissible {
        padding-right: 2.5rem;
    }

    .resbtn-center {
        margin-top: 20px;
    }

    .res-add-btn {
        margin: 0 auto !important;
    }

    /* step-4 */
    .res-w-250 {
        width: 250px !important;
    }

    .res-margn-top {
        margin-top: 20px !important;
    }

    .res-marginbotm {
        text-align: center !important;
        margin-top: 20px;
    }

    .res-step4 {
        display: flex;
        margin-top: 25px;
    }

    .res-btn-no {
        margin-right: 20px !important;
    }

    /* step-5 */
    .res-padding {
        padding: 0px !important;
    }

    .res-w-100 {
        width: 100% !important;
    }

    .res-center-bottom {
        text-align: center !important;
        margin-bottom: 20px !important;
    }

    .res-font-18 {
        font-size: 18px !important;
    }
}


/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) and (max-width: 991px) {

    /* login-page */
    .sign {
        text-align: center;
        margin-top: -20px;
    }

    .loginlogo {
        margin-top: -5px !important;
    }

    /* header-part */
    .logo {
        margin-left: 20px !important;
    }

    .navbar-light .navbar-toggler {
        margin-right: 20px;
    }

    .container {
        max-width: -webkit-fill-available;
        padding-right: 25px;
        padding-left: 25px;
        margin-right: auto;
        margin-left: auto;
    }

    /* list-view */
    .res-margintop {
        margin-top: 30px;
        padding-left: 10px;
        margin-bottom: 20px;
    }

    .res-text {
        padding: 5px;
        margin-top: -6px !important;
    }

    .res-date-text {
        display: flex;
    }

    /* job nformation */
    .card-data-center {
        text-align: center !important;
    }

    .res-card {
        margin-top: 20px !important;
    }

    .res-float-left {
        float: left !important;
    }

    .res-label-mnl-38 {
        margin-left: -38px;
    }

    .res-margn-p-145 {
        margin-left: 145px !important;
    }

    .res-margn-n-35 {
        margin-left: -35px !important;
    }

    .res-tab1 {
        margin-left: 0px !important;
    }

    .res-tab2 {
        margin-left: -42px !important;
    }

    .res-row-left {
        margin-left: 30px !important;
        margin-bottom: 10px !important;
    }

    .res-margn-pl-50 {
        margin-left: 70px !important;
    }

    .res-single {
        margin-left: 146px !important;
    }

    .res-ml-n-35 {
        margin-left: 155px !important;
        text-align: center !important;
    }

    .res-job-btn {
        margin-right: 30px !important;
    }

    .res-job-text {
        float: left;
        font-size: 21px;
    }

    /*-------- select-day-view ------*/
    .page-heading {
        font-size: 24px;
    }

    .padingleft-0 {
        padding-left: 0px !important;
    }

    .margnleft-0 {
        margin-left: 0px !important;
    }

    .margintop-0 {
        margin-top: 0px !important;
    }

    .res-margintop {
        margin-top: -5px !important;
        margin-bottom: 15px;
    }

    .p-text {
        margin-top: 50px !important;
        margin-left: 0px !important;
    }

    .time {
        width: 100% !important;
        display: inline-flex;
    }

    .w-auto {
        width: 60% !important;
    }

    /* step-1.4 */
    .alerticon {
        width: 25px !important;
    }

    .res-font-14 {
        font-size: 17px !important;
    }

    .res-font-20 {
        font-size: 20px !important;
        margin-left: -40px !important;
    }

    .alert-dismissible {
        padding-right: 2.5rem;
    }

    .res-flex {
        display: flex;
    }

    .res-btn-center {
        clear: both;
        margin: 0 auto !important;
    }

    /* model popup */
    .responsive-w {
        width: 100%;
    }

    .responsive-w1 {
        width: 94%;
    }

    .res-item {
        margin-left: 16px !important;
        ;
    }

    /* step-4 */
    .res-w-250 {
        width: 250px !important;
    }

    .res-margn-top {
        margin-top: 20px !important;
    }

    .margnleft-0 {
        margin-left: 0px !important;
    }

    /* step-5 */
    .res-padding {
        padding: 0px !important;
    }

    .res-w-100 {
        width: 100% !important;
    }

    .res-center-bottom {
        text-align: center !important;
        margin-bottom: 20px !important;
    }

    .res-marginbotm {
        text-align: center !important;
        margin-top: 20px;
    }

    .res-step4 {
        text-align: center !important;
        margin-top: 25px;
    }

    .res-btn-no {
        margin-right: 25px !important;
    }

    .margintop-0 {
        margin-top: 0px !important;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) and (max-width: 1199px) {

    /* login-page */
    .sign {
        text-align: center;
        margin-top: -20px;
    }

    .loginlogo {
        margin-top: -5px !important;
    }

    /* job information */
    .res-margn-n-30 {
        margin-left: -42px !important;
    }

    .res-margn-n-32 {
        margin-left: -41px;
    }

    .res-ml-n-35 {
        margin-left: 235px !important;
        text-align: center !important;
    }

    .res-job-btn {
        margin-right: 30px !important;
    }

    /* step-1.4 */
    .alerticon {
        width: 25px !important;
    }

    .res-font-14 {
        font-size: 20px !important;
    }

    .res-font-20 {
        font-size: 24px !important;
        margin-left: -20px !important;
    }

    .alert-dismissible {
        padding-right: 2.5rem;
    }

    /* step-5 */
    .res-w-100 {
        width: 100% !important;
    }

    .res-marginbotm {
        text-align: center !important;
        font-size: 19px;
    }

    .res-step4 {
        display: flex;
    }

    .res-btn-no {
        margin-right: 25px !important;
    }

    /*-------- select-day-view ------*/
    .page-heading {
        font-size: 24px;
    }

    .padingleft-0 {
        padding-left: 0px !important;
    }

    .margnleft-0 {
        margin-left: 0px !important;
    }

    .margintop-0 {
        margin-left: -10px;
    }

    .resp-font-14 {
        font-size: 14px;
    }

    .w-auto {
        margin-left: 2px !important;
        width: 150px !important
    }
}


/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {}

//  extra add css styles in calndar
body .fc .fc-toolbar .fc-button {
    font-size: 13px !important;
    margin-right: 20px !important;
    padding: 8px !important;
    border-radius: 6px !important;
}

.fc-toolbar h2 {
    font-size: 1.75em;
    margin: 0;
    text-align: center !important;
    font-weight: 600 !important;
    font-family: "Muli", sans-serif !important;
    // margin-left: -525px !important;
}

.fc-event {
    position: relative;
    display: block;
    font-size: 1em !important;

    height: 30px;
    width: 30px;
    border-radius: 50% !important;
    // width: 24px !important;
    // margin-bottom: 5px !important;
    text-align: center !important;
}

.searchalign {
    top: 50px;
    position: relative;
}

.shadow-lg {
    box-shadow: 0 0rem 1rem rgba(0, 0, 0, .175) !important;
}

p-fullcalendar.usercalnder.fc.fc-toolbar,
.fc-center h2 {
    // position: relative;
    // left: 205px;
    font-size: 1.75em;
    margin: 0;
    color: #555555 !important;
    text-align: left !important;
    font-weight: 600 !important;
    font-family: "Muli", sans-serif !important;
    margin-left: -485px !important;
}

p-fullcalendar.usercalnder,
.fc-ltr {
    margin-bottom: 50px !important;
}

p-fullcalendar.model-calnd .fc-center h2 {
    // position: relative;
    // left: 205px;
    font-size: 1.75em;
    margin: 0;
    color: #555555 !important;
    text-align: left !important;
    font-weight: 600 !important;
    font-family: "Muli", sans-serif !important;
    margin-left: -280px !important;
}

p-fullcalendar.normal-calnd .fc-center h2 {
    // position: relative;
    // left: 205px;
    font-size: 1.75em;
    margin: 0;
    color: #555555 !important;
    text-align: left !important;
    font-weight: 600 !important;
    font-family: "Muli", sans-serif !important;
    margin-left: -400px !important;
}

// *************************** Calnderata *******************************//
.adminreshedule p-fullcalendar.normal-calnd .fc-center h2 {
    font-size: 1.75em;
    margin: 0;
    color: #555555 !important;
    text-align: left !important;
    font-weight: 600 !important;
    font-family: "Muli", sans-serif !important;
    margin-left: -360px !important;
}

.admincollection p-fullcalendar.normal-calnd .fc-center h2 {
    font-size: 1.75em;
    margin: 0;
    color: #555555 !important;
    text-align: left !important;
    font-weight: 600 !important;
    font-family: "Muli", sans-serif !important;
    margin-left: -465px !important;
}

.voluntercollection p-fullcalendar.normal-calnd .fc-center h2 {
    font-size: 1.75em;
    margin: 0;
    color: #555555 !important;
    text-align: left !important;
    font-weight: 600 !important;
    font-family: "Muli", sans-serif !important;
    margin-left: -485px !important;
}

// calndar data styles
.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
    font-size: 20px !important;
    float: left !important;
}

.fc-dayGrid-view .fc-day-number,
.fc-dayGrid-view .fc-week-number {
    padding: 10px !important;
}

p-fullcalendar.monthly-calnd .fc-row .fc-content-skeleton {
    position: relative;
    z-index: 4;
    padding-bottom: 2px;
    // background-color: rgba(189, 189, 189, 0.5) !important;
}

p-fullcalendar.monthly-calnd .fc th {
    padding: 0.571em 1em;
    font-size: 22px !important;
}

p-fullcalendar.monthly-calnd .fc-unthemed td.fc-today {
    // background: #c1c1c1 !important;
    border-radius: 5px;
}

.fc-scroller {
    -webkit-overflow-scrolling: touch;
    overflow: hidden !important;
    height: 100% !important;
}

.shadow {
    box-shadow: 0 0rem 1rem rgba(0, 0, 0, .15) !important;
}

// admin clander css

p-fullcalendar.monthly-calnd .fc-button-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 100px !important;
}

.fc-day-top.fc-other-month {
    opacity: 1 !important;
}

.fc td,
.fc th {
    border-color: none;
    // border-style: solid;
    // border-width: unset !important;
    padding: 0;
    vertical-align: top;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select {
    margin-top: -0.35em;
    margin-bottom: 0;
    transition: color 0.2s, box-shadow 0.2s;
    border: none !important;
}

.ui-datepicker .ui-datepicker-title select {
    font-size: 1.4em !important;
    margin: 0, 5em 8px !important;
    vertical-align: middle;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    top: 70% !important;
    margin-top: -.5em;
    margin-left: -.5em;
}

.ui-datepicker select.ui-datepicker-month {
    margin-right: 2em !important;
}

body .ui-datepicker table td.ui-datepicker-today>span {
    border-radius: 100px !important;
    opacity: 1 !important;
    padding: 5px !important;
}

body .ui-datepicker table td>a,
body .ui-datepicker table td>span {
    display: block;
    padding: 0.4em !important;
}

.ui-calendar .ui-datepicker {
    min-width: 100%;
    position: absolute !important;
}


/*---------------------------------  Admin Portal CSS ----------------------------------------------- */

.sidebar {
    overflow: scroll;
    // height: 525px !important;
    overflow-x: hidden;
    padding-right: 5px;
    padding-bottom: 15px;
}

ul#accordionSidebar:before {
    position: fixed;
    content: "";
    width: 2px;
    height: 100%;
    left: 240px;
    top: 0;
    height: 100vh;
}

.bg-Japanese-Laurel {
    background: #6e2c91;
}

.text-Japanese-Laurel {
    color: #6e2c91;
}

// .text-Purple {
//     color: #b41e8d;
// }

/* NOTIFICATION BLOCK STARTS */

.notification-block {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9898989;
}

.width400 {
    width: 400px;
}

.width320 {
    width: 320px;
}

.width600 {
    width: 600px;
}


/* NOTIFICATION BLOCK ENDS */

.font-11 {
    font-size: 11px;
}

.font-13 {
    font-size: 13px;
}

.font-weight-600 {
    font-weight: 600;
}


/*  FONTS ENDS */


/* MARGINS STARTS */

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mr-20 {
    margin-right: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.ml-10 {
    margin-left: 10px;
}


/* MARGINS ENDS */


/* PADDING and fonts styles STARTS */

.p-15 {
    padding: 15px;
}

.pt-15 {
    padding-top: 15px;
}

.font-weight-500 {
    font-weight: 500;
}


/* PADDING ENDS */

#wrapper {
    display: flex;
}

.sidebar {
    width: 305px;
    height: calc(100vh - 100px);
    background: #fff;
}

// .topbar,
// .logo {
//     height: 60px;
// }
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    height: 60px;
    display: flex;
    align-items: center;
}

.sidebar .nav-item {
    position: relative;
    /* font-weight: 600; */
}

.sidebar .nav-item .nav-link {
    width: 100%;
    text-align: left;
    padding: 1rem;
    /* width: 14rem; */
    font-weight: 700;
    font-size: 16px;
}

.sidebar .nav-item .nav-link i {
    margin-right: 15px;
}

#wrapper #content-wrapper {
    width: 100%;
    // overflow-x: hidden;
}

.sidebar.toggled {
    overflow: visible;
    width: 4rem !important;
    margin-left: 2px;
}

// .sidebar.toggled .nav-item .nav-link span {
//     font-size: .65rem;
//     display: none;
// }
.sidebar .sidebar-brand .sidebar-brand-text {
    display: inline;
    margin-left: 1rem !important;
}

.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile {
    height: 2rem;
    width: 2rem;
}

.sidebar.toggled .sidebar-brand .sidebar-brand-text {
    display: none;
}

.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
    position: absolute;
    /* transform: scale(.7); */
    /* transform-origin: top right; */
    right: .25rem;
    top: 5px;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 150px;
    flex-shrink: 0;
    padding: 0;
}

.toggle-button,
.toggle-button:hover,
.toggle-button:focus,
.toggle-button:active {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    width: 36px;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
}

.sidebar .nav-item .nav-link.active {
    height: 66px;
    width: 235px;
}

.activeborder {
    border-left-width: medium;
    border-left-style: groove;
}

.iconbottom {
    margin-bottom: 30px;
}


/* CUSTOM STARTS */

.toolbar-profile-dropdown .dropdown-menu,
.toolbar-notification-dropdown .dropdown-menu {
    right: 0;
    left: auto;
}

.toolbar-notification-dropdown .dropdown-toggle::after,
.toolbar-profile-dropdown .dropdown-toggle::after {
    display: none;
}

.notification-bubble {
    width: 4px;
    height: 4px;
    border-radius: 150px;
    position: absolute;
    right: 8px;
    top: 18px;
}

.sidebar-brand-icon {
    width: 270px;
}

.toolbar-input-group {
    height: 40px;
}

.toolbar-input-group .btn {
    background: none;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
}

.toolbar-input-group .btn span {
    position: relative;
    top: 5px;
}

.toolbar-input-group .form-control {
    height: 36px;
    font-size: 14px;
    padding-left: 0;
}

.border-radius-rounded {
    border-radius: 150px;
}


/* Tabs styles */

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show {
    border-bottom-right-radius: 3px;
    border-top-left-radius: 3px;
    font-weight: 500;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.nav-tabs .nav-link {
    border: none !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-size: 22px;
}

.nav-tabs {
    border-bottom: none !important;
}

/* end */

.card {
    border: none !important;
    border-radius: 0;
}

.page-link {
    border: none;
    margin-left: 2px;
    padding: 10px 15px;
    font-size: 18px;
}

.page-link.disabled {
    opacity: 0.5;
}

.page-link.active {
    font-weight: 600;
    padding: 0.4rem 1rem;
    line-height: 1.5;
    border-radius: 8px;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.mr-100p {
    margin-right: 120px;
}

.carousel-control-prev,
.carousel-control-next {
    opacity: 1;
    padding: 5px;
    height: fit-content;
    margin-top: 180px;
}

.slider-carsoual {
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 49%) 0px 1px 10px 0px;
}


/* NEWLY ADDED BY SRIKANTH */


/* .container-fluid{
    width: 100%;
    padding-right: 0px;
     padding-left: 0px;
} */

#myDIV {
    position: relative;
    margin-left: 300px;
    width: auto;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-40 {
    margin-left: 40px;
}

.input_container {
    position: relative;
    padding: 0 0 0 3px;
    direction: ltr;
    width: 165px;
    border-radius: 10px;
}

.font-12 {
    font-size: 12px;
}

.h-75px {
    height: 75px;
}

.w-75px {
    width: 75px
}

.h-60p {
    height: 60px;
}

.h-70p {
    height: 70px;
}

.height-55p {
    height: 55px;
}

.h-50p {
    height: 50px;
}

.w-90 {
    width: 90%;
}

.h-30p {
    height: 30px;
}

.font-50 {
    font-size: 50px !important;
}

.alerticon {
    width: 45px;
}


/* newly add styles */

.card-enquirey {
    border: 2px solid silver !important;
}

.mb-40 {
    margin-bottom: 40px;
}

.w-40 {
    width: 40%;
}

.h-42p {
    height: 42px;
}

.w-42p {
    width: 42px;
}

.checklist-cricle {
    width: 25px;
    height: 25px;
    border-radius: 50px;
}

textarea {
    resize: none;
}

.card-header.note-toolbar {
    padding: 0 0 5px 0px !important;
    margin: 0;
    background: $color-main !important;
}

.mt-11pn {
    margin-top: -11px !important;
}


/* CUSTOM CHECKBOX STARTS */

.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

.custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 9px;
    top: 6px;
    width: 5px;
    height: 10px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.w-30 {
    width: 30%;
}

.h-32 {
    height: 32px;
}

.h-55p {
    height: 55px !important;
}

.h-190p {
    height: 190px;
}

.h-280p {
    height: 280px;
}

.width-120px {
    width: 120px;
}

.height-120px {
    height: 120px;
}

.close:hover,
.close:focus {
    outline: none !important;
    border: none !important;
}

input[type="search"] {
    cursor: pointer;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
}

.bg-lightgreen {
    background: #95F27E;
}

.bg-mayablue {
    background: #78BDFA;
}

.hintdata {
    position: absolute;
    top: 160px;
    display: flex !important;
    flex-direction: row;
    text-align: center;
    left: 40px;
}


/* CUSTOM CHECKBOX END;S */

// clander styles in admin
.ui-inputtext {
    font-size: 16px !important;
    width: 80% !important;
    height: 45px !important;
}

.ui-colorpicker>.ui-inputtext {
    width: 30px !important;
    height: 30px !important;
    border-radius: 30px !important;
    position: absolute !important;
    top: 4px !important;
    left: 4px !important;
}

// p-table styling
body .ui-table .ui-table-thead>tr>th {
    padding: 12px !important;
    font-weight: 400 !important;
    // font-size: 20px !important;
    color: white;
    border: none !important;
    font-family: "Muli", sans-serif !important;
}

.ui-table .ui-table-tbody>tr:nth-child(odd) {
    vertical-align: middle;
}

.ui-table .ui-table-tbody>tr:nth-child(even) {
    vertical-align: middle;
}

body .ui-table .ui-table-tbody>tr>td {
    padding: 4px;
    font-size: 18px !important;
    border: none !important;
    font-family: "Muli", sans-serif !important;
}

//.ui-paginator .ui-paginator-page, .ui-paginator .ui-paginator-pages, .ui-paginator .ui-paginator-next, .ui-paginator .ui-paginator-last, .ui-paginator .ui-paginator-first, .ui-paginator .ui-paginator-prev, .ui-paginator .ui-paginator-current
.ui-paginator .ui-paginator-element {
    border-radius: 6px !important;
    font-size: 12px !important;
    padding: 0px !important;
    margin: 4px !important;
}

body .ui-paginator {
    border: 0 none !important;
    padding: 5px !important;
    margin-top: 20px !important;
}

body .ui-multiselect .ui-multiselect-label {
    width: 190px;
}

.ui-autocomplete {
    width: 100% !important;
}

// component calnders
p-autocomplete.van .ui-inputtext {
    font-size: 16px !important;
    width: 90% !important;
    height: 45px !important;
    border-color: #c8c8c87d !important;
    outline: none !important;
    border-radius: 8px 0px 0px 8px !important;
}

// p-autocomplete .driver .ui-inputtext {
//     font-size: 16px !important;
//     width: 430px !important;
//   }
p-calendar.driver .ui-inputtext {
    width: 90% !important;
    height: 45px !important;
    border-color: #c8c8c87d !important;
    outline: none !important;
    border-radius: 8px 0px 0px 8px !important;
}

.ui-calendar {
    width: 100% !important;
}

.ui-datepicker {
    top: auto !important;
}

p-calendar.staff .ui-inputtext {
    width: 90% !important;
    height: 45px !important;
    border-color: #c8c8c87d !important;
    outline: none !important;
    border-radius: 8px 0px 0px 8px !important;
}

body .ui-multiselect {
    background: $color-main;
    border: none !important;
    width: -webkit-fill-available !important;
    transition: border-color 0.2s, box-shadow 0.2s;
}

.ui-multiselect:focus,
.ui-multiselect:visited {
    outline: none !important;
}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
    color: black !important;
    background-color: $color-main !important;
}

body .ui-inputtext:enabled:focus:not(.ui-state-error) {
    // border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active,
.ui-chkbox .ui-chkbox-box.ui-state-active:hover {
    border-color: $color-primary !important;
    background-color: $color-primary !important;
    color: $color-main;
    font-weight: bold;
}

// p-table sorticon style changes by sivaprasad
.ui-table p-sorticon {
    vertical-align: baseline !important;
    font-weight: lighter !important;
}

.ui-sortable-column-icon:before {
    font-family: 'FontAwesome' !important;
    content: "\f107" !important;
    font-weight: normal !important;
    font-size: 20px;
    color: $color-main !important;
    // content: "\e906" !important;
}

.ui-sortable-column-icon.pi-sort-amount-down:before {
    font-family: 'FontAwesome' !important;
    font-size: 20px;
    color: $color-main !important;
    content: "\f107" !important;
    //content: "\e906" !important;
}

.ui-sortable-column-icon.pi-sort-amount-up-alt:before {
    // font-size: 20px;
    // color: $color-main !important;
    // content: "\e907" !important;
    font-family: 'FontAwesome' !important;
    font-size: 20px;
    color: $color-main !important;
    content: "\f106" !important;
}

.ui-paginator-prev,
.ui-paginator-next {
    display: none !important;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page {
    color: #242424 !important;
    font-weight: bold !important;
}

body .ui-table .ui-sortable-column:focus {
    box-shadow: none !important;
}

.ui-datepicker table a.ui-state-default {
    color: $color-primary !important;
    font-weight: bold !important;
}

.ui-table .ui-table-thead>tr>th {
    width: -moz-max-content;
    width: -webkit-auto-content;
}

p-multiselect.modalvechile .ui-multiselect {
    background: $color-main;
    transition: border-color 0.2s, box-shadow 0.2s;
    padding: 8px !important;
    border-radius: 3px !important;
    border: 1px solid #c8c8c87d !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px !important;
    width: 100% !important;
}

p-multiselect.modalvehicletype .ui-multiselect {
    background: $color-main;
    transition: border-color 0.2s, box-shadow 0.2s;
    padding: 8px !important;
    border-radius: 3px !important;
    border: 1px solid #c8c8c87d !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px !important;
    width: 100% !important;
}

body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:focus,
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

body .fc .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0px;
    box-shadow: none !important;
}

body .ui-button:enabled:focus {
    outline: 0 none;
    outline-offset: 0px;
    box-shadow: none !important;
}

body .ui-inputtext:enabled:focus:not(.ui-state-error) {
    outline-offset: 0;
    box-shadow: none !important;
    border-color: $color-primary !important;
}

body .ui-datepicker table td>a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

.ui-table .ui-table-tbody>tr:nth-child(odd):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

.fc-button .fc-icon {
    vertical-align: middle;
    font-size: 1.5em;
    font-weight: 600 !important;
}

// colorpicker
p-colorpicker .vanchecklist.ui-colorpicker>.ui-inputtext {
    width: 30px !important;
    height: 30px !important;
    border-radius: 30px !important;
    position: absolute !important;
    top: 9px !important;
    left: 4px !important;
}

.ui-colorpicker-overlay-panel {
    top: 45px !important;
}

// ------------------------------------------media quries -----------------------------------
@media (min-width:1024px) and (max-width:1200px) {
    .sidebar {
        width: calc(28vw) !important;
        height: calc(100vh - 100px);
        background: $color-main;
    }
}

// @media (min-width:1201px) and (max-width:1280px) {
//     .sidebar {
//         width: calc(24vw);
//         height: calc(100vh - 100px);
//         background: $color-main;
//     }
// }
@media (min-width:1201px) and (max-width:1366px) {
    // .sidebar {
    //     width: calc(22.5vw) !important;
    //     height: calc(100vh - 100px);
    //     background: $color-main;
    // }
}

@media (min-width:1400px) {
    .sidebar {
        width: calc(20.2vw) !important;
        height: calc(100vh - 100px);
        background: $color-main;
    }

    .marginleft-2p {
        margin-left: 0px;
    }

    // calnder styles
    p-fullcalendar.usercalnder.fc.fc-toolbar,
    .fc-center h2 {
        font-size: 1.75em;
        margin: 0;
        color: #555555 !important;
        text-align: left !important;
        font-weight: 600 !important;
        font-family: "Muli", sans-serif !important;
        margin-left: -560px !important;
    }

    .admincollection p-fullcalendar.normal-calnd .fc-center h2 {
        font-size: 1.75em;
        margin: 0;
        color: #555555 !important;
        text-align: left !important;
        font-weight: 600 !important;
        font-family: "Muli", sans-serif !important;
        margin-left: -510px !important;
    }
}

@media screen and (min-width: 1600px) {
    .sidebar {
        width: calc(18vw) !important;
        height: calc(100vh - 100px);
        background: $color-main;
    }

    .marginleft-2p {
        margin-left: 0px;
    }

    // calnder styles
    p-fullcalendar.usercalnder.fc.fc-toolbar,
    .fc-center h2 {
        font-size: 1.75em;
        margin: 0;
        color: #555555 !important;
        text-align: left !important;
        font-weight: 600 !important;
        font-family: "Muli", sans-serif !important;
        margin-left: -650px !important;
    }
}

//---------------------------------end media quries-------------------->
//-----------------------start of brower comptability isuues------------------->
// --------------------mozilla--------------------------------->
@-moz-document url-prefix() {

    .carousel-control-next:focus,
    .carousel-control-next:hover,
    .carousel-control-prev:focus,
    .carousel-control-prev:hover {
        color: $color-main;
        text-decoration: none;
        outline: 0;
        opacity: .9;
    }

    a:hover {
        color: none !important;
    }

    .carousel-control-prev,
    .carousel-control-next {
        height: 8% !important;
    }

    select {
        word-wrap: normal;
        -moz-appearance: none;
        background-position-x: 98% !important;
        background-position-y: center;
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #cccc;
        padding-right: 25px !important;
    }

    body .ui-table .ui-table-thead>tr>th {
        // width: -moz-available !important;
    }
}

// Loader styling
.multi-loader .la-ball-pulse>div:nth-child(1) {
    -webkit-animation-delay: -200ms;
    -moz-animation-delay: -200ms;
    -o-animation-delay: -200ms;
    animation-delay: -200ms;
    color: #ff0000;
}

.multi-loader .la-ball-pulse>div:nth-child(2) {
    -webkit-animation-delay: -100ms;
    -moz-animation-delay: -100ms;
    -o-animation-delay: -100ms;
    animation-delay: -100ms;
    color: #FFA500;
}

.multi-loader .la-ball-pulse>div:nth-child(3) {
    -webkit-animation-delay: -100ms;
    -moz-animation-delay: -100ms;
    -o-animation-delay: -100ms;
    animation-delay: -100ms;
    color: #00FF00;
}

.shadow-sm {
    // box-shadow: none !important;
}

p-autocomplete.bookingauto .ui-inputtext {
    border: 1px solid #ced4da;
    border-radius: 8px;
    width: 100% !important;
    height: calc(1.5em + 1rem + 2px) !important;
    padding: .5rem 1rem !important;
    font-size: 1.25rem !important;
    line-height: 1.5;
    border-color: #c8c8c87d !important;
}

.fc-view-container {
    position: relative;
    margin-top: 66px !important;
}

.fc .fc-toolbar {
    align-items: center;
    display: flex;
    justify-content: start;
}

.p-calendar-w-btn .p-datepicker-trigger {
    background-color: #5BC3BC;
    border: none;
}

.p-calendar-w-btn .p-datepicker-trigger:hover {
    background-color: #5BC3BC;
}


.p-multiselect {
    // min-width: 500px;
}


.p-steps.number {
    font-size: 1000px !important;
}


.p-datatable .p-datatable-thead>tr>th {
    color: white;
}


.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: white;
    margin-left: 0.5rem;
}




::ng-deep .fc .fc-daygrid-day.fc-day-today {
    background-color: rgb(229, 248, 225, 0.5) !important;
}


::ng-deep .fc.fc-theme-standard .fc-view-harness .fc-view {
    background: red !important;
}


.fc .fc-daygrid-day.fc-day-today {
    background-color: #5BC3BC;
    color: white;
}


::ng-deep .fc .fc-theme-standard a {
    color: #5BC3BC;
}



textarea,input,select {
    // .shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    // }
}